@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap&subset=latin,cyrillic");

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Impact Regular';
    font-display: swap;
    font-style: normal;
    font-weight: normal;
    src: local('Impact Regular'), url('./fonts/impact.woff') format('woff');
}


@font-face {
    font-family: 'Impact Unicode';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: local('Impact Regular'), url('./fonts/unicode.impact.woff') format('woff');
}


@font-face {
    font-family: 'Impacted Regular';
    font-style: normal;
    font-display: swap;
    font-weight: normal;
    src: local('Impacted Regular'), url('./fonts/Impacted.woff') format('woff');
}

body {
    font-family: "Inter", sans-serif !important;
    min-width: 320px;
    overflow-x: hidden;
    font-size: 24px;
    @apply text-lg bg-gray-200 dark:bg-darkBg  text-[#1E1E1E] dark:text-white  transition-colors;
    overscroll-behavior: none;
}

* {
    box-sizing: border-box
}

body.tv {
    @apply min-h-screen;
}

#root {
    @apply min-h-screen;
}

body.dark {
    font: 'Inter', sans-serif !important;
    @apply bg-darkBg text-white;
}

.btn {
    @apply rounded-md p-2 cursor-pointer font-normal transition text-base border border-transparent;
}

.btn.tv-btn {
    @apply px-6 py-2 xl:px-8 xl:py-4 xl:text-xl text-lg cursor-pointer font-normal transition;
}

.btn.btn-wide {
    @apply px-8;
}

.btn-primary {
    @apply btn text-white bg-gray-900 font-medium dark:bg-transparent dark:border-white dark:hover:bg-blockColor hover:bg-gray-600;

    &.disabled {
        @apply bg-gray-200 dark:bg-gray-600 opacity-100 dark:text-white/30 text-[#1119284D] !important;
    }
}

.btn-info {
    @apply btn text-white bg-gray-200 font-medium  dark:bg-gray-500 hover:bg-gray-300 dark:hover:bg-gray-400;

    &.active {
        @apply bg-green-500 hover:bg-green-500 dark:hover:bg-green-500 text-white;
    }

    &.disabled {
        @apply bg-gray-200 dark:bg-gray-600 opacity-100 dark:text-white/30 text-[#1119284D] !important;
    }
}

.btn-white {
    @apply btn bg-gray-900 dark:bg-white text-white dark:text-gray-900 font-medium  hover:bg-gray-600 dark:hover:bg-gray-400;
}

.btn-success {
    @apply btn bg-green-500 font-medium text-white dark:bg-green-400 hover:bg-green-600 dark:hover:bg-green-600/70;
}

.btn-loading,
button.disabled,
button[disabled] {
    @apply cursor-not-allowed pointer-events-none opacity-60;
}

.btn-outlined {
    @apply border-gray-900  dark:border-gray-500 font-medium  hover:border-gray-400 hover:text-gray-400 dark:hover:border-gray-400 !important;
    @apply btn bg-transparent border  ;
}

.btn-danger {
    @apply btn bg-red-500 text-white dark:bg-red-400 hover:bg-red-600 dark:hover:bg-red-600/70;
}

@keyframes loadingF {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.input {
    min-height: 42px;
    @apply border ring-gray-300 border-gray-300 bg-gray-50  rounded-md py-1 px-3 w-full  transition shadow-none outline-none placeholder-gray-400 hover:border-gray-400;
    @apply dark:ring-gray-500 dark:border-gray-500 dark:bg-gray-700;
    @apply dark:bg-blockColor dark:border-darkBorder;
    @apply focus:outline-none focus:shadow-none focus:ring-2 focus:ring-gray-300 ;
}

body.tv .input {
    min-height: 42px;
}

input {
    outline: none !important;
    @apply focus-visible:outline-0 focus-within:outline-0 !important;
}

.input-no-border {
    @apply border-none ring-0;
}

.input-sm {
    min-height: 28px;
    @apply p-1;
}

.search-input {
    @apply bg-gray-50  border ring-gray-300 border-gray-300  rounded-lg py-2 px-4 min-w-full  transition shadow-none outline-none;
    @apply dark:ring-gray-500 dark:border-gray-500 dark:bg-gray-700;
    @apply focus:outline-none focus:shadow-none focus:ring-2 focus:ring-gray-300;
}

.input.invalid {
    @apply border-red-500 !important;
}


.sidebar {
    max-width: 500px;
    transform: translateX(-100%);

    @apply basic-block flex flex-col top-0 left-0 bottom-0 fixed w-full h-full max-h-svh overflow-y-auto;

    @media screen and (max-width: 576px) {
        width: 100vw;
        height: 100vh;
    }
}

.sidebar-enter {
    transform: translateX(-100%);
}

.sidebar-enter-active,
.sidebar-enter-done {
    transform: translateX(0%);
    transition: transform 300ms ease-in-out;
}

.sidebar-exit {
    transform: translateX(0%);
}

.sidebar-exit-active,
.sidebar-exit-done {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
}

.sidebar-nav-item {
    @apply flex items-center gap-3 hover:text-orange-400 py-4 transition;

    .icon {
        @apply transition;
    }

    &:hover .icon {
        @apply text-orange-400 !important;
    }

    &.mobile {
        @apply flex md:hidden;
    }

    &.active {
        @apply text-orange-400;
    }
}

table {
    @apply w-full border-collapse;
}

.basic-block {
    @apply bg-white dark:bg-darkBg;
}

.generic-table {
    @apply w-full border-collapse;

    th {
        @apply py-2 px-4 text-left font-medium text-gray-500 dark:text-gray-400  border-b dark:border-darkBorder border-gray-300 whitespace-nowrap;
    }

    td {
        @apply py-2 px-4 border-b border-gray-300 dark:border-darkBorder whitespace-nowrap;
    }

    .table-head {
        @apply text-sm text-gray-700 uppercase bg-gray-50 dark:border-darkBorder dark:text-gray-400;
    }

    .table-head th {
        @apply text-gray-500;
    }

    thead tr {
        @apply bg-gray-100 dark:bg-blockColor;
    }

    tbody tr {
        @apply even:bg-gray-200 hover:bg-gray-300 even:dark:bg-[#222222] even:hover:dark:bg-[#222222]/90;
        @apply  dark:bg-blockColor bg-gray-50 dark:hover:bg-blockColor/90   h-full  border-b transition border-gray-300 dark:border-darkBorder cursor-pointer;
    }

    th, td {
        &.medium {
            @apply font-medium;
        }

        &.gray {
            @apply text-gray-500 dark:text-gray-400;
        }

        &.breaking-words {
            word-wrap: break-word !important;
            word-break: break-word !important;
        }

        @apply px-2 py-4 xl:p-4 h-full relative text-sm;
    }
}

.skeleton {
    background-image: linear-gradient(270deg, #cccccc, rgba(255, 255, 255, 0.4), #cccccc) !important;
    color: transparent !important;
    border-color: transparent !important;
    background-size: 600% 600%;
    animation-name: skeletonAnimation;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    will-change: background-position;

    @apply text-transparent;
}

body.dark .skeleton{
    background-image: linear-gradient(270deg, #333, rgba(34, 34, 34, 0.4), #333) !important;
}

.skeleton:not(.rounded-full) {
    @apply rounded-md
}

@keyframes skeletonAnimation {
    0% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0 50%;
    }
}

.page-title {
    line-height: normal !important;
    @apply text-2xl font-semibold dark:text-white text-[#1E1E1E] mb-4 flex items-center flex-wrap;
}

.page-title .page-subtitle:after {
    content: '';
    display: inline-flex;
    position: relative;
    bottom: 5px;
    width: 1em;
    height: 2px;
    background: #1E1E1E;
    margin: auto 8px;
    @apply dark:bg-white;
}

.page-subtitle {
    @apply text-2xl font-medium text-[#1E1E1E] dark:text-white  relative;
}

.block {
    @apply bg-white rounded-md border dark:bg-blockColor dark:border-darkBorder border-gray-300 p-4;
}
.settings-block{
    @apply bg-white rounded-md border dark:bg-blockColor dark:border-darkBorder border-gray-300 px-4 py-2;

}

.device-card {
    @apply transition p-2 md:p-5 h-full flex flex-col rounded-md relative;
    aspect-ratio: 240/300;
    @media screen and (max-width: 1024px) {
        aspect-ratio: 4/3;
    }
}

.device-card.device-online {
    @apply bg-white dark:bg-blockColor hover:bg-gray-100;
}

.device-card.device-offline {
    @apply bg-gray-50 dark:bg-blockColor hover:opacity-100;
}

.device-card.device-taken {
    @apply bg-green-500 text-white hover:bg-green-400 !important;
}

.device-card.cluster-master{

}
.device-card.cluster-slave .device-card--subtitle{
}

.device-card.device-taken .device-card--subtitle {
    @apply text-sm text-white;
}

.device-card.device-taken .device-card--status {
    @apply text-white;
}

.device-card--status {
    @apply font-medium capitalize;
}

.device-card--subtitle {
    @apply text-sm font-normal text-gray-400;
}

.device-card-inactive {
    @apply device-card bg-gray-100 items-center justify-center skeleton;
    aspect-ratio: 240/300;
}

.device-card-empty {
    aspect-ratio: 180/240;
    @apply cursor-pointer hover:bg-gray-300/80 items-center justify-center;
    @apply device-card bg-gray-300 dark:bg-[#222222];
}

.auth-form {
    @apply flex gap-3 rounded-md border border-gray-300 dark:border-darkBorder dark:bg-blockColor bg-white w-auto md:w-full flex-col p-4 md:p-10 mx-3 my-5 max-w-[380px];
}

body.tv .auth-form {
    @apply max-w-[520px] lg:text-2xl text-xl rounded-[10px] p-10 !important;
}

body.tv .auth-form .btn {
    @apply text-xl lg:text-2xl
}

body.tv .auth-form h1 {
    @apply text-4xl;
}

.devices-list.expanded .device-card {
    aspect-ratio: unset;
    min-height: 230px;
}

.devices-list {
    animation-duration: 400ms !important;
}

.devices-list.expanded {
    @apply p-5 fixed inset-0 z-10 max-h-svh overflow-y-scroll;
    transform: translate(0, 0) scale(1);
}

.devices-list.collapsed {
    transform: scale(1);
}

.fullscreen-enter {
    transform: scale(1);
    /*opacity: 1;*/
}

.fullscreen-enter-active {
    transform: scale(1.5);
    /*opacity: 0.8;*/
    transition: all 200ms ease-in-out;
}

.fullscreen-exit {
    transform: scale(1.5);
    /*opacity: 0.3;*/
}

.fullscreen-exit-active {
    transform: scale(1);
    /*opacity: 0.8;*/
    transition: all 200ms ease-in-out;
}

.order-screen-btn {
    @apply flex items-start hover:bg-secondary focus:border-white hover:border-white border border-transparent outline-0 gap-2 lg:gap-4 rounded-[10px] flex-col lg:p-10 p-4 transition;

    &:hover, &:focus {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .4);
    }
}

/*react-datepicker__day react-datepicker__day--002 react-datepicker__day--in-range*/
/*react-datepicker__day react-datepicker__day--001 react-datepicker__day--selected react-datepicker__day--range-start react-datepicker__day--in-range react-datepicker__day--weekend*/
.react-datepicker__day--selected,
.react-datepicker__day--in-range,
.react-datepicker__day--selecting-range-start,
.react-datepicker__day--selecting-range-end {
    @apply bg-gray-900 text-white !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start),
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-end) {
    @apply bg-red-900/50 text-white !important;
}
.react-datepicker{
    font-family: "Inter", sans-serif !important;
    @apply border-gray-300 rounded-[5px] border !important;
}
.react-datepicker__day--keyboard-selected{
    @apply bg-transparent !important;
}
.react-datepicker__month{
    @apply bg-white !important;
}
.react-datepicker__header {
    @apply bg-gray-200 border-gray-300 !important;
}

.apexcharts-tooltip{
    box-shadow: none !important;
}

.select-button .w-px.h-full.inline-block.text-white.bg-gray-300.text-opacity-0{
    @apply hidden;
}